import ResearchNewsService from 'src/common/researchNews';
import { getRouteConfigFromNavigationMenu } from 'src/domains/insight/common/components/navigationMenu/helpers/getRouteConfigFromNavigationMenu';

import useUpgradeModal from './common/components/upgradePackages/useUpgradeModal';
import {
  INSIGHT_HOMEPAGE_ROUTE,
  INSIGHT_NEWS_DETAILS_ROUTE,
  INSIGHT_REPORT_DETAILS_ROUTE,
  INSIGHT_RESEARCH_UPDATE_DETAILS_ROUTE,
  INSIGHT_SEARCH_ROUTE,
  INSIGHT_WEBINAR_DETAILS_ROUTE,
  INSIGHT_AUTHOR_DETAILS_ROUTE,
  INSIGHT_DRAFT_PREVIEWS_ROUTE,
  INSIGHT_TAG_HOMEPAGE_ROUTE,
} from './insight.constants';

import { forceRefreshGuard } from 'src/services/appUpdate.service';

import type {
  NavigationGuardNextRaw,
  RouteLocationNormalized,
  RouteRecordRaw,
} from '@kpler/vue2-utils';
import type { DecodedToken } from 'src/domains/insight/insight.types';

const { toggleModal } = useUpgradeModal();

const InsightHomepage = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/homepage/components/InsightHomepage.vue'
  );
const InsightLayout = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/common/components/InsightLayout.vue'
  );
const ShortContentDetailsContainer = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/shortContentPage/components/ShortContentDetailsContainer.vue'
  );
const WebinarDetailsContainer = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/webinarPage/components/WebinarDetailsContainer.vue'
  );
const ReportDetailsContainer = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/reportPage/components/ReportDetailsContainer.vue'
  );
const InsightAuthorPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/authorPage/InsightAuthorPage.vue');

const InsightSearchPage = () =>
  import(/* webpackChunkName: "insight" */ 'src/domains/insight/searchPage/InsightSearchPage.vue');
const InsightDraftsPreviewPage = () =>
  import(
    /* webpackChunkName: "insight" */ 'src/domains/insight/draftPreviewPage/InsightDraftsPreviewPage.vue'
  );

let decodedToken: DecodedToken;
type Permissions = Pick<
  DecodedToken,
  | 'hasNews'
  | 'hasResearchUpdates'
  | 'hasReports'
  | 'hasWebinars'
  | 'hasDataAddOns'
  | 'hasDraftAccess'
>;

const hasAccess = (permissionName: keyof Permissions): boolean => decodedToken[permissionName];

const beforeEnterFromToken =
  (permissionNames: Array<keyof Permissions>) =>
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNextRaw,
  ) => {
    const nextWithRefresh = forceRefreshGuard(to, next);
    decodedToken = await ResearchNewsService.getPermissionsFromToken();
    if (!permissionNames.some(hasAccess)) {
      toggleModal();
      return nextWithRefresh({ name: INSIGHT_HOMEPAGE_ROUTE });
    }
    return nextWithRefresh();
  };

const insightRoutes: RouteRecordRaw = {
  name: 'insight',
  path: 'insight',
  component: InsightLayout,
  redirect: INSIGHT_HOMEPAGE_ROUTE,
  children: [
    ...getRouteConfigFromNavigationMenu(),
    {
      path: '/',
      name: INSIGHT_HOMEPAGE_ROUTE,
      components: {
        default: InsightHomepage,
      },
      meta: {
        title: 'Insight',
      },
    },
    {
      name: INSIGHT_TAG_HOMEPAGE_ROUTE,
      path: 'tag/:tagSlug',
      components: {
        default: InsightHomepage,
      },
      props: {
        default: route => ({
          tagSlug: route.params.tagSlug,
        }),
      },
      meta: {
        title: 'Insight - Tagged Content',
      },
      beforeEnter: beforeEnterFromToken([
        'hasNews',
        'hasResearchUpdates',
        'hasReports',
        'hasWebinars',
      ]),
    },
    {
      name: INSIGHT_NEWS_DETAILS_ROUTE,
      path: 'news/:slug',
      components: {
        default: ShortContentDetailsContainer,
      },
      props: { default: { context: 'news' } },
      meta: {
        title: 'Insight News',
      },
      beforeEnter: beforeEnterFromToken(['hasNews']),
    },
    {
      name: INSIGHT_RESEARCH_UPDATE_DETAILS_ROUTE,
      path: 'research/research-updates/:slug',
      components: {
        default: ShortContentDetailsContainer,
      },
      props: { default: { context: 'researchUpdates' } },
      meta: {
        title: 'Insight Market Pulse',
      },
      beforeEnter: beforeEnterFromToken(['hasResearchUpdates']),
    },
    {
      name: INSIGHT_REPORT_DETAILS_ROUTE,
      path: 'research/reports/:slug',
      components: {
        default: ReportDetailsContainer,
      },
      meta: {
        title: 'Insight Report',
      },
      beforeEnter: beforeEnterFromToken(['hasReports']),
    },
    {
      name: INSIGHT_WEBINAR_DETAILS_ROUTE,
      path: 'research/webinars/:slug',
      components: {
        default: WebinarDetailsContainer,
      },
      meta: {
        title: 'Insight Webinar',
      },
      beforeEnter: beforeEnterFromToken(['hasWebinars']),
    },
    {
      name: INSIGHT_SEARCH_ROUTE,
      path: 'search',
      components: {
        default: InsightSearchPage,
      },
      meta: {
        title: 'Insight Search',
      },
      beforeEnter: beforeEnterFromToken([
        'hasNews',
        'hasResearchUpdates',
        'hasReports',
        'hasWebinars',
      ]),
    },
    {
      name: INSIGHT_AUTHOR_DETAILS_ROUTE,
      path: 'author/:slug',
      components: {
        default: InsightAuthorPage,
      },
      meta: {
        title: 'Insight Author',
      },
      beforeEnter: beforeEnterFromToken([
        'hasNews',
        'hasResearchUpdates',
        'hasReports',
        'hasWebinars',
        'hasDataAddOns',
      ]),
    },
    {
      name: INSIGHT_DRAFT_PREVIEWS_ROUTE,
      path: 'drafts',
      components: {
        default: InsightDraftsPreviewPage,
      },
      meta: {
        title: 'Insight Drafts Preview',
      },
      beforeEnter: beforeEnterFromToken(['hasDraftAccess']),
    },
    // the next routes go there
  ],
};

export default insightRoutes;
